html {
  font-size: 62.5%;
}
body {
  margin: 0;
  background-color: #f0f0f0;
}
::-webkit-scrollbar {
  display: none;
}

/* Webfont: OpticianSans-Regular */
@font-face {
  font-family: 'OpticianSans';
  src: url('fonts/Optiker-K.eot'); /* IE9 Compat Modes */
  src: url('fonts/Optiker-K.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('fonts/Optiker-K.woff') format('woff'),
    /* Modern Browsers */ url('fonts/Optiker-K.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
